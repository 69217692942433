import tw from "tailwind.macro"
import React from "react"
// import styled from "@emotion/styled"

const Wrapper = tw.div`
  flex flex-wrap text-left relative w-screen justify-center flex-col z-50 px-10 sm:px-10 md:px-10 mt-20 sm:mt-20 lg:mt-40 lg:px-20 xl:px-20 
`
const Heading = tw.h1`
text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl font-black
`
const ParagraphOne = tw.p`
  text-sm sm:text-md md:text-lg lg:text-lg xl:text-lg font-medium text-gray-800
`
const Container = tw.div`
  py-5
`
// const ParallaxImg = styled.img`
//   ${tw`absolute z-0`}
//   opacity: 0.20;
//   left: 0%;
//   height: 100%;
//   width: 100vw;
// `

export default () => (
    <Wrapper>
        <Container>
            <Heading>One-stop-shop for all your car rental needs</Heading>
            <ParagraphOne>Whether your business need a car for Airport transfers, Daily rentals, Outstation travel - all can be booked with easy to use app. Allows company to rent for daily, weekly or Monthly.</ParagraphOne>
        </Container>
        <Container>
            <Heading>Special contracted car rental rates</Heading>
            <ParagraphOne>Business will have peace of mind with special contracted rates for all transportation needs like Airport transfers, daily rentals, outstation travel for every city in India.</ParagraphOne>
        </Container>
        <Container>
            <Heading>Travel management system</Heading>
            <ParagraphOne>Cloud based system allow to rent and manage all bookings, authorize employees, get notification of every booking, download reports.</ParagraphOne>
        </Container>
        <Container>
            <Heading>Reduce company fleet</Heading>
            <ParagraphOne>Reduce investment on purchasing company owned cars and rent only when you need anytime anywhere in India</ParagraphOne>
        </Container>
        <Container>
            <Heading>30,000 vehicles available all over India</Heading>
            <ParagraphOne>Allows you to rent a car from varied fleet from anywhere in India. We assure you get clean fleet and experienced drivers</ParagraphOne>
        </Container>
        <Container>
            <Heading>Care kit</Heading>
            <ParagraphOne>Every car is sanitized after every use and will be provided with a mask, hand sanitizer, paper napkins, water bottle, phone charger & snack/cookies.</ParagraphOne>
        </Container>
        {/* <ParallaxImg src={require('../svgs/bg-shape-icon.svg')}/> */}
    </Wrapper>
)
