import { urls } from "../api/urls"
import axios from "axios"
import { storeKey, retrieveKey } from "../asyncStorage"

export function updateLocations(data) {
    return {
        type: 'UPDATE_LOCATIONS',
        data
    }
}

export function fetchLocations() {
    return function (dispatch, getState) {
        let url = urls.fetchLocations;
        axios.post(url, {})
            .then(response => {
                console.log("Locations Response", response)
                let updatedArray = [];
                if (response.data.responseData.length > 0) {
                    response.data.responseData.map((location) => {
                        updatedArray.push(Object.assign({}, location, { label: location.name, value: location.id }))
                    })
                    storeKey("locations", updatedArray)
                }
                else {
                    retrieveKey('locations')
                        .then(data => {
                            updatedArray = data
                        })
                }
                dispatch(updateLocations(updatedArray))
            })
            .catch(err => {
                console.log("Locations Error --->", err)
                retrieveKey('locations')
                    .then(data => {
                        dispatch(updateLocations(data))
                    })
            })
    }
}


export function registerBusiness(data, success, error) {
    return function (dispatch, getState) {
        let url = urls.registerBusiness
        console.log("Input Data For Register Business", data);
        axios.post(url, data)
            .then(response => {
                console.log("Register Business Response Data", response)
                if (response.data.statusCD === 200) {
                    success("Request sent", response.data.responseData)
                }
                else if (response.data.statusCD === 404) {
                    success("Request already received", response.data.responseData)
                }
                else {
                    error("Faild to send", response)
                }
            })
            .catch(err => {
                console.log("Register Business Error --->", err)
                error(err)
            })
    }
}