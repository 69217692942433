import tw from "tailwind.macro"
import React from "react"
import { css } from '@emotion/core'
import "../fonts/fonts.css"

const Wrapper = tw.div`
  flex items-center absolute justify-center w-full top-0 z-50
`
const ContainerOne = tw.div`
  flex items-center w-1/2 md:w-1/4 lg:w-1/4 px-5 lg:px-10 py-5
`
const ContainerTwo = tw.div`
  flex items-center justify-end w-1/2 md:w-9/12 lg:w-9/12 px-5 lg:px-10 py-5
`
const Text = tw.p`
  ml-1 mb-1 sm:mb-1 md:mb-1 lg:mb-1 text-gray-600 text-lg md:text-lg lg:text-3xl font-medium
`

export default () => (
  <Wrapper>
    <ContainerOne>
      <img
        css={css`${tw`h-10 md:h-10 lg:h-16`}`}
        src={require('../svgs/logo-with-light-text.svg')}
        alt="Zyppys Logo" />
      <Text>business</Text>
    </ContainerOne>
    <ContainerTwo></ContainerTwo>
  </Wrapper>
)
