import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
// import SectionOne from "../components/SectionOne"
import SectionTwo from "../components/SectionTwo"
import SectionThree from "../components/SectionThree"
import RegistrationForm from "../components/RegistrationForm"
import Footer from "../components/Footer"
import Header from "../components/Header"
import "../fonts/fonts.css"
import { Global, css } from "@emotion/core"
import { connect } from "react-redux"
// import { fetchLocations } from "../actions/appAction"

const Wrapper = tw.div`
  flex items-center relative justify-center w-full flex-col bg-white
`

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentWillMount() {
    // this.props.dispatch(fetchLocations())
  }

  scrollToForm = () => {
    let element = window.document.getElementById("Form")
    element.scrollIntoView()
  }

  render() {
    return (
      <Wrapper className="font-face" >

        <Global styles={styles} />
        <SEO title="Home" />
        <Header />
        {/* <SectionOne /> */}
        <SectionTwo scrollToForm={this.scrollToForm} />
        <SectionThree />
        <RegistrationForm />
        <Footer />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(Index);

const styles = css`
  .font-face {
    font-family: 'Chromatica', Helvetica, Arial, sans-serif; /* 1 */
    text-rendering: optimizeLegibility;
	  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    line-height: 1.618; /* 2 */
    -ms-text-size-adjust: 100%; /* 3 */
    -webkit-text-size-adjust: 100%; /* 3 */
    font-size: 14px;
    @media 
    (-webkit-min-device-pixel-ratio: 1.5), 
    (min-resolution: 144dpi){ 
        /* Retina-specific stuff here */
        font-size: 14px;
    }
  }
`;